import { fetchWorkspaceDailyPerformance } from "modules/workspaceDailyPerformance/api";
import { useWrappedQuery } from "utils/reactQueryHooks";
import {endOfDay, getDaysInMonth, startOfMonth, subDays, subMonths} from 'date-fns';
import _ from 'lodash'
import { Box, Paper } from "@mui/material";
import { Flex } from "components/Flex/Flex";
import { formatNum } from "utils/transformUtils";
import {GridSortModel} from '@mui/x-data-grid-pro';
import config from "config/config";
import { useEffect, useState } from "react";

const columns = ['workspaceId', 'workspace', 'defaultRevenue', 'cost', 'profit', 'month'];
const now = new Date();
const date = [startOfMonth(subMonths(now, 2)), endOfDay(subDays(now, 1))]
const thisMonth = now.toISOString().slice(0, 7);

const sortModel: GridSortModel = [{field: 'workspaceId', sort: 'desc'}, {field: 'month', sort: 'desc'}]
export function MonthlyProfitPanel({filter}) {
	const [workspaceProfits, setWorkspaceProfits] = useState<any[]>([]);

	const {isLoading, data: statsResp} = useWrappedQuery({
		queryKey: ['MonthlyProfitPanel', filter.workspaces],
		queryFn: () => fetchWorkspaceDailyPerformance({
			columns,
			sortModel,
			filter: {
				dateRange: date,
			},
		}),
	});
	
	useEffect(() => {
		if (!statsResp?.items) {
			setWorkspaceProfits([]);
			return;
		};
		const grouped = Object.values(_.groupBy(statsResp.items, w => w.workspace.id))
		const sorted = _.sortBy(grouped, g => g[0].profit).reverse();
		setWorkspaceProfits(sorted);
	}, [statsResp])
	return (
		<Flex justify="flex-start" sx={{flexWrap: 'wrap', alignItems: 'stretch'}}>
			{workspaceProfits?.map(group => {
				const firstStat = group[0];
				return (
					<Paper key={firstStat.workspace.id} sx={{px: 2, py: 1, display: 'inline-flex'}}>
						<Box component="div" sx={{color: firstStat.workspace.color, fontWeight: 'bold', mr: 1, fontSize: 20, display: 'inline-block', width: 50}}>{firstStat.workspace.name}</Box>
						<Box>
							{group.map(stat => {
								const profit = (stat?.defaultRevenue || 0) * config.expectedApprovalPercent - (stat?.cost || 0);
								const roi = stat?.cost ? (profit / stat?.cost * 100) : 0;
								const month = new Date(`${stat.month}-01`)
								const numDays = getDaysInMonth(month)
								const avgProfit = profit / numDays
								const profitSx = thisMonth === stat.month ? {fontWeight: 'bold'} : {fontSize: 12}
								const monthStr = month.toLocaleString('en-US', { month: 'short' });
								return (
									(
										<Box>
											<Box component="span" sx={profitSx}>${formatNum(profit, 0)}</Box>
											<Box component="span" sx={{fontSize: 12, ml: 0.5}}>({monthStr}, ${formatNum(avgProfit, 0)} avg, {formatNum(roi, 0)}% roi)</Box>
										</Box>
									)
								)
							})}
						</Box>
					</Paper>
				)
			})}
		</Flex>
	)
}