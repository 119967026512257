import _ from 'lodash';
import { Box, Paper, Skeleton } from "@mui/material";
import { WorkspaceOfferStats } from "./WorkspaceOfferStats";
import { WorkspaceOverview } from "./WorkspaceOverview";
import DateRangePicker from "components/Form/DateRangePicker";
import AutoComplete from "components/Form/AutoComplete";
import { useWorkspaces } from "context/WorkspaceContext";
import { useState } from "react";
import { subDays } from "date-fns";
import { TrafficSourceBalancePanel } from './TrafficSourceBalancePanel';
import Grid from '@mui/material/Unstable_Grid2';
import { MonthlyProfitPanel } from './MonthlyProfitPanel';


function DashboardFilter({filter, setFilter}) {
	const {workspaces, workspacesMap} = useWorkspaces();

	return (
		<Box sx={{display: 'flex', alignSelf: 'flex-start', gap: 2, mt: 1, flexWrap: 'wrap'}}>
			<Paper>
				<DateRangePicker
					name="dateRange"
					value={filter.dateRange}
					label="Date Range"
					textFieldProps={{sx: {minWidth: 290}, size: 'small'}}
					onChange={(newValue) => {setFilter({...filter, dateRange: newValue})}}
				/>
			</Paper>
			<Paper>
				<AutoComplete
					label="Workspaces"
					options={workspaces}
					value={filter.workspaces}
					onChange={(_e, newVal) => setFilter({...filter, workspaces: _.orderBy(newVal, i => workspacesMap[i]?.order)})}
					size="small"
					multiple
					textFieldProps={{sx: {width: 270}, size: 'small'}}
					limitTags={2}
					getLimitTagsText={(more) => `+${more}`}
				/>
			</Paper>
		</Box>
	)
}
export function DashboardPage() {
	const {activeWorkspaceIds} = useWorkspaces();
	const [filter, setFilter] = useState({
		dateRange: [subDays(new Date(), 10), subDays(new Date(), 1)] as [Date, Date], workspaces: [] as string[],
	})

	
	if (!activeWorkspaceIds?.length) {return (
		<Skeleton sx={{height: 120, transform: 'none'}} />
	)}
	return (
		<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
			<DashboardFilter filter={filter} setFilter={setFilter} />
			<Grid container spacing={2} sx={{mt: 1}}>
				<Grid xs={12} sx={{height: '100%'}}>
					<MonthlyProfitPanel filter={filter}  />
				</Grid>
				<Grid xs={12} lg={8} sx={{height: '100%'}}>
					<WorkspaceOverview filter={filter}  />
				</Grid>
				<Grid xs={12} lg={4}>
					<TrafficSourceBalancePanel filter={filter}  />
				</Grid>
			</Grid>
			<WorkspaceOfferStats filter={filter}  />
		</Box>
	);
}