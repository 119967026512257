import {Paper, Box} from '@mui/material';
import {
	Add as AddIcon,
	Edit as EditIcon,
	ContentCopy as ContentCopyIcon,
	Campaign as CampaignIcon,
	Link as LinkIcon,
	BarChart as BarChartIcon,
	AutoFixHigh as AutoFixHighIcon,
} from '@mui/icons-material';
import _ from 'lodash';

// components
import PageTitle from "../../components/PageTitle";
import { useAvailableWindowHeight, useModalState, useQueryableFilter } from "utils/hooks";
import config from "config/config";
import { useMutateArrayItemQuery, useWrappedMutation, useWrappedQuery } from "utils/reactQueryHooks";
import { autoAdjustCampaignsWeights, fetchCampaigns, upsertCampaign } from "modules/campaigns/api";
import { EnhancedDataGrid } from "../../components/DataGrid/EnhancedDataGrid";
import { Campaign } from "modules/campaigns/api.interface";
import { useSelector } from "react-redux";
import * as SettingSelectors from 'modules/settings/selectors';
import { CountryFlag } from "components/CountryFlag/CountryFlag";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useWorkspaces } from 'context/WorkspaceContext';
import { getCampaignLink } from 'utils/campaignUtils';
import { formatNum } from 'utils/transformUtils';
import { formatError } from 'modules/campaigns/api';
import { useState } from 'react';
import AsyncAutoComplete from 'components/Form/AsyncAutoComplete';
import { fetchNetworks, fetchSites, fetchTrafficSources } from 'utils/autoComplete';
import TooltipButton from 'components/TooltipButton/TooltipButton';
import CountriesSelect from 'components/Form/CountriesSelect/CountriesSelect';

function getColumns({renderActionsColumn}): GridColDef[] {
	return [
		{ field: 'name', headerName: 'Name', minWidth: 350, flex: 1},
		{ field: 'trafficSourceId', headerName: 'TS', minWidth: 120, valueFormatter: (_, row) => row.trafficSource.name},
		{ field: 'totalCap', headerName: 'Total Cap', width: 100, sortable: false, valueGetter: (_val, row) => formatNum(_.sumBy(row.offers, 'trafficCapLimit'))},
		{ field: 'numOffers', headerName: '# Offers', width: 100, sortable: true, valueFormatter: (value) => formatNum(value)},
		{ field: 'geo', headerName: 'Geo', minWidth: 100, renderCell: ({value}) => <CountryFlag country={value} />},
		{ field: 'activityStatus.yesterday.visits::numeric', headerName: 'T. Yesterday', width: 100, sortable: true, valueFormatter: (value) => formatNum(value), valueGetter: (_, row) => row.activityStatus?.yesterday?.visits},
		{ field: 'activityStatus.today.visits::numeric', headerName: 'T. Today', width: 100, sortable: true, valueFormatter: (value) => formatNum(value), valueGetter: (_, row) => row.activityStatus?.today?.visits},
		{ field: 'comments', headerName: 'Comments', minWidth: 350, flex: 1, editable: true},
		{ field: 'createdAt', headerName: 'Created At', type: 'string', valueFormatter: (value: string) => value?.slice(0, 10),  width: 100},
		{ field: 'updatedAt', headerName: 'Updated At', type: 'string', valueFormatter: (value: string) => value?.slice(0, 10),  width: 100},
		{ field: 'actions', headerName: 'Actions', sortable: false, renderCell: renderActionsColumn, width: 150},
	]
}


function ActionsColumn({row, onEditClick, onDuplicateClick, onStatsClick}) {
	const globalSettings = useSelector(SettingSelectors.globalSettings)
	return (
		<>
			<TooltipButton
				title="Copy Campaign URL"
				icon={LinkIcon}
				size="medium"
				onClick={() => {
					const campaignLink = getCampaignLink(row, row.trafficSource, globalSettings);
					navigator.clipboard.writeText(campaignLink);
				}}
				sx={{p: 0.5}}
			/>
			<TooltipButton
				title="Open Stats"
				icon={BarChartIcon}
				size="medium"
				onClick={() => onStatsClick(row)}
				sx={{p: 0.5}}
			/>
			<TooltipButton
				title="Duplicate"
				icon={ContentCopyIcon}
				size="medium"
				onClick={() => onDuplicateClick(row)}
				sx={{p: 0.5}}
			/>
			<TooltipButton
				title="Edit Campaign"
				icon={EditIcon}
				size="medium"
				onClick={() => onEditClick(row)}
				sx={{p: 0.5}}
			/>
		</>
	)
}

const defaultFilter = {text: '', geo: []};
const defaultSortModel = [{field: 'updatedAt', sort: 'desc'}]
export function CampaignsPage() {
	const {filter, pagination, sortModel, setFilter, setPagination, setSortModel} = useQueryableFilter({defaultFilter, defaultPageSize: config.paginationDefaultValue, defaultSortModel});
	const gridHeight = useAvailableWindowHeight()
	const {setModal: setCampaignModal} = useModalState('campaign')
	const {selectedWorkspaceIds} = useWorkspaces();
	const [inlineCampaignId, setInlineCampaginId] = useState<string>();
	const {setModal: setCampaignStatsModal} = useModalState('campaignStats')

	const {isLoading: isCampaignsLoading, data: campaignsResponse} = useWrappedQuery({
		queryKey: ['campaignsList', filter, pagination, sortModel, selectedWorkspaceIds],
		queryFn: () => fetchCampaigns({
			pagination,
			sortModel: sortModel,
			filter: {
				text: filter.text,
				campaign: {trafficSourceId: filter.trafficSourceId, geo: filter.geo.length ? {op: 'in', value: filter.geo} : null},
				offer: {networkId: filter.networkId, siteId: filter.siteId},
			},
			workspaceIds: selectedWorkspaceIds,
		}),
	});
	
	const saveCampaignQuery = useMutateArrayItemQuery({
		kind: 'upsert',
		mutationFn: (data: any) => upsertCampaign({campaign: data}),
		queryKey: inlineCampaignId ? ['campaign', inlineCampaignId] : [],
		queryListKey: ['campaignsList'],
		formatError,
	})

	const autoAdjustWeightsQuery = useWrappedMutation({
		mutationFn: (campaignIds: string[]) => autoAdjustCampaignsWeights({campaignIds}),
	});

	function openCampaignModal(id?: string, reason?: string) {
		setCampaignModal({id, open: true, reason})
	}

	function renderActionsColumn(params) {
		return (
			<ActionsColumn row={params.row}
				onEditClick={() => openCampaignModal(params.row.id, 'Update')}
				onDuplicateClick={() => openCampaignModal(params.row.id, 'Duplicate')}
				onStatsClick={row => setCampaignStatsModal({campaignId: row.id, campaignName: row.name, open: true})}
			/>
		);
	}

	function handleDoubleCellClick(params) {
		if (!['actions', 'comments'].includes(params.field)) {
			openCampaignModal(params.row.id, 'Update')
		}
	}

	function handleUpdatedInline(campaign) {
		setInlineCampaginId(campaign.id);
		saveCampaignQuery.mutateAsync(campaign)
			.catch(console.error)
	}

	return (
		<>
			<PageTitle title="Campaigns" icon={CampaignIcon} />
			<Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
				<Paper sx={{height: gridHeight}}>
					<EnhancedDataGrid<Campaign>
						items={campaignsResponse?.items}
						loading={isCampaignsLoading}
						columns={getColumns({renderActionsColumn})}
						rowCount={campaignsResponse?._meta?.total}
						rowHeight={38}
						pagination
						paginationMode="server"
						paginationModel={pagination}
						onPaginationModelChange={newModel => setPagination(newModel)}
						sortingMode="server"
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						pageSizeOptions={config.paginationOptions}
						editMode='cell'
						onProcessRowUpdateError={console.log}
						checkboxSelection
						processRowUpdate={(newRow, oldRow) => {
							const validFields = ['comments'];
							const toUpdate = _.pickBy(newRow, (val, key) => validFields.includes(key) && newRow[key] !== oldRow[key])
							if (!_.isEmpty(toUpdate)) {
								handleUpdatedInline({...toUpdate, id: newRow.id});
							}
							return newRow
						}}
						pluralName="Campaigns"
						filter={filter}
						setFilter={setFilter}
						showHiddenToggle
						// actionSubmitted={campaignJustSubmitted}
						// actionSubmitting={campaignSubmitting}
						actions={[{
							icon: AddIcon,
							primary: true,
							hint: 'Add new campaign',
							onClick: () => openCampaignModal(),
						}]}
						filters={(
							<>
								<AsyncAutoComplete
									entityName='trafficSource'
									limit={15}
									getItems={fetchTrafficSources}
									label="Traffic Source"
									name="trafficSourceId"
									value={filter.trafficSourceId}
									onChange={(newValue) => {setFilter({...filter, trafficSourceId: newValue})}}
									where={{trafficSource: {workspaceId: !!selectedWorkspaceIds.length ? {op: 'in', value: selectedWorkspaceIds} : null}}}
									sx={{flex: 1}}
									textFieldProps={{sx: {minWidth: 200}, size: 'small'}}
									size="small"
								/>
								<AsyncAutoComplete
									entityName='network'
									limit={15}
									getItems={fetchNetworks}
									label="Network"
									name="networkId"
									value={filter.networkId}
									onChange={(newValue) => {setFilter({...filter, networkId: newValue})}}
									where={{network: {workspaceId: !!selectedWorkspaceIds.length ? {op: 'in', value: selectedWorkspaceIds} : null}}}
									sx={{flex: 1}}
									textFieldProps={{sx: {minWidth: 200}, size: 'small'}}
									size="small"
								/>
								<AsyncAutoComplete
									entityName='site'
									limit={15}
									getItems={fetchSites}
									label="Site"
									name="siteId"
									value={filter.siteId}
									onChange={(newValue) => {setFilter({...filter, siteId: newValue})}}
									where={{site: {workspaceId: !!selectedWorkspaceIds.length ? {op: 'in', value: selectedWorkspaceIds} : null}}}
									sx={{flex: 1}}
									textFieldProps={{sx: {minWidth: 200}, size: 'small'}}
									size="small"
								/>
								<CountriesSelect
									label="Geo"
									name="geo"
									size="small"
									value={filter.geo}
									onChange={(newValue) => {setFilter({...filter, geo: newValue})}}
									textFieldProps={{sx: {minWidth: 200}, size: 'small'}}
									multiple
									noAll
								/>
							</>
						)}
						onCellDoubleClick={handleDoubleCellClick}
						selectionActions={[{
							icon: AutoFixHighIcon,
							hint: 'Auto Adjust Weights',
							onClick: autoAdjustWeightsQuery.mutate,
						}]}
						actionSubmitted={autoAdjustWeightsQuery.isSuccess}
						actionSubmitting={autoAdjustWeightsQuery.isLoading}
					/>
				</Paper>
			</Box>
		</>
	)
}
