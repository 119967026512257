import _ from 'lodash';
import { Box, Paper } from "@mui/material";
import { EnhancedDataGrid } from "components/DataGrid/EnhancedDataGrid";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import { fetchNetworkCommissions } from 'modules/networkCommissions/api';
import { useWrappedQuery } from 'utils/reactQueryHooks';
import { useEffect, useState } from 'react';
import DateRangePicker, { defaultPresets } from 'components/Form/DateRangePicker';
import { Flex } from 'components/Flex/Flex';
import { startOfDay, startOfMonth, subMonths } from 'date-fns';
import config from 'config/config';
import { Workspace } from 'context/WorkspaceContext';
import AsyncAutoComplete from 'components/Form/AsyncAutoComplete';
import { fetchNetworks } from 'utils/autoComplete';
import { formatNum } from 'utils/transformUtils';

const numCol: Partial<GridColDef> = {type: 'number', rowSpanValueGetter: _.noop, sortable: false, valueFormatter: (value) => formatNum(value, 0)};
const columns: GridColDef[] = [
	{field: 'month', headerName: 'Month', sortable: false},
	{field: 'network', headerName: 'Network', width: 150, sortable: false, valueGetter: (val: any) => val.name, rowSpanValueGetter: _.noop},
	{field: 'cost', headerName: 'Cost', ...numCol},
	{field: 'expectedRev', headerName: 'Expected Rev', valueGetter: (_, row) => row.defaultRevenue * config.expectedApprovalPercent, ...numCol},
	{field: 'rejectionPercent', headerName: 'Min/Max Rej %', valueGetter: (_, row) => `${formatNum((row.rejectedSum) / row.totalSum * 100, 0)}% / ${formatNum((row.rejectedSum + row.pendingSum) / row.totalSum * 100, 0)}%`, rowSpanValueGetter: _.noop},
	{field: 'defaultRevenue', headerName: 'Grs Rev', description: 'from Venus', ...numCol},
	{field: 'totalSum', headerName: 'Total Revenue', description: 'from API', ...numCol},
	{field: 'rejectedSum', headerName: 'Rejected', ...numCol},
	{field: 'pendingSum', headerName: 'Pending', ...numCol},
	{field: 'approvedSum', headerName: 'Approved', ...numCol},
	{field: 'paidSum', headerName: 'Paid', ...numCol},
]
const columnKeys = ['workspace', 'network', 'month', 'totalSum', 'rejectedSum', 'pendingSum', 'approvedSum', 'awaitingPaymentSum', 'paidSum'];
const now = new Date();
const today = startOfDay(now);
const fourMonthAgo = startOfMonth(subMonths(today, 4));
const defaultFilter = {dateRange: [fourMonthAgo, today]};
const sortModel = [{field: 'month', sort: 'asc'}, {field: 'networkName', sort: 'asc'}] as GridSortModel;
export function MonthlyWorkspaceDetail({workspace}: {workspace: Workspace}) {
	const [filter, setFilter] = useState(defaultFilter);
	const [finalItems, setFinalItems] = useState([]);
	const [uiFilter, setUIFilter] = useState({networkId: [] as string[]});
	const {isLoading, data: stats, refetch} = useWrappedQuery({
		queryKey: ['monthlyTab', workspace.id, filter],
		queryFn: () => fetchNetworkCommissions({columns: columnKeys, filter: {...filter, workspaceId: [workspace.id]}, sortModel}),
		// initialData: {items: [], pagination: {total: 0}, totals: {}}
	});
    
	useEffect(() => {
		if (!stats?.length) {
			setFinalItems([]);
			return;
		};

		let finalItems = stats;
		if (uiFilter.networkId?.length) {
			finalItems = finalItems.filter((item) => uiFilter.networkId.includes(item.network.id));
		}
		setFinalItems(finalItems);
	}, [stats, uiFilter])
	return (
		<Flex component={Paper} direction='column' align='strech' sx={{p: 2}}>
			<Box sx={{fontSize: 24, color: workspace.color}}>{workspace.name} Summary</Box>
			<EnhancedDataGrid
				loading={isLoading}
				rowHeight={30}
				items={finalItems}
				autoHeight
				sx={{
					'& .total-row': {
						backgroundColor: '#f5f5f5',
						fontWeight: 'bold',
					},
				}}
				filters={(
					<>
						<DateRangePicker
							name="dateRange"
							value={filter.dateRange}
							label="Date Range"
							onChange={(newValue) => {setFilter({...filter, dateRange: newValue})}}
							textFieldProps={{sx: {alignSelf: 'flex-start'}, size: 'small'}}
							datesPresets={defaultPresets}
						/>
						<AsyncAutoComplete
							entityName='network'
							limit={15}
							getItems={fetchNetworks}
							label="Network"
							name="networkId"
							value={uiFilter.networkId}
							multiple
							onChange={(newValue) => {setUIFilter({...uiFilter, networkId: newValue})}}
							where={{network: {workspaceId: workspace.id}}}
							sx={{flex: 1}}
							textFieldProps={{sx: {minWidth: 200}, size: 'small'}}
							size="small"
						/>
					</>
				)}
				getRowId={(row) => `${row.network.id}-${row.month}`}
				getRowClassName={(params) => params.row.isTotal ? 'total-row' : ''}
				unstable_rowSpanning
				pluralName="Commissions"
				columns={columns}
				noSearch
				hideFooter
			/>
		</Flex>
	)
}