import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useWrappedQuery } from 'utils/reactQueryHooks';
import { fetchWorkspaces } from 'utils/autoComplete';
import _ from 'lodash';
import * as AuthSelectors from 'modules/auth/selectors';
import { useSelector } from 'react-redux';
import { Dictionary } from 'tr-typescript-library';

export interface Workspace {id: string; name: string; order: number, color: string}
interface WorkspacesContextProps {
	workspaces: Workspace[], workspacesIds: string[],
	workspacesMap: Dictionary<Workspace>, loading: boolean,
	selectedWorkspaces: Workspace[], selectedWorkspaceIds: string[],
	activeWorkspaces: Workspace[], activeWorkspaceIds: string[],
	selectedSingleWSId?: string, setSelectedWorkspaces: (workspaceIds: string) => void
}


const storedConfig = JSON.parse(localStorage.getItem('vnSelectedWorkspaces') || '{"ids": []}');
const selectedWorkspaceIds = storedConfig.ids;
const selectedSingleWSId = selectedWorkspaceIds.length === 1 ? selectedWorkspaceIds[0] : undefined;
const WorkspacesContext = createContext<WorkspacesContextProps>({workspaces: [], workspacesIds: [], workspacesMap: {}, loading: true, selectedWorkspaceIds, selectedWorkspaces: [], activeWorkspaceIds: [], activeWorkspaces: [], selectedSingleWSId, setSelectedWorkspaces: _.noop});

export const WorkspacesProvider: React.FC<any> = ({ children }) => {
	const [workspacesProps, setWorkspaceProps] = useState<WorkspacesContextProps>({workspaces: [], workspacesIds: [], workspacesMap: {}, loading: true, selectedWorkspaceIds, selectedWorkspaces: [], activeWorkspaceIds: [], activeWorkspaces: [], selectedSingleWSId, setSelectedWorkspaces: _.noop});
	const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);

	const {isLoading: loading, data: workspacesItems} = useWrappedQuery({
		enabled: !!isLoggedIn,
		queryKey: ['workspaces'],
		queryFn: () => fetchWorkspaces(),
	});

	const setSelectedWorkspaces = useCallback((workspaces) => {
		localStorage.setItem('vnSelectedWorkspaces', JSON.stringify(workspaces))
		const selectedSingleWorkspace = workspaces.ids.length === 1 ? workspaces.ids[0] : undefined;
		const selectedWorkspaces = workspaces.ids.map(id => workspacesProps.workspacesMap[id]).filter(i => i);
		setWorkspaceProps({
			...workspacesProps,
			selectedWorkspaces: selectedWorkspaces,
			selectedWorkspaceIds: workspaces.ids,
			selectedSingleWSId: selectedSingleWorkspace,
			activeWorkspaceIds: selectedWorkspaceIds?.length ? selectedWorkspaceIds : workspacesProps.workspacesIds,
			activeWorkspaces: selectedWorkspaces?.length ? selectedWorkspaces : workspacesProps.workspaces,
		});
	}, [workspacesProps])

	useEffect(() => {
		const workspaces = workspacesItems || [];
		const workspacesMap = _.keyBy(workspaces, 'id');
		const workspacesIds = workspaces?.map(w => w.id) || [];
		const selectedWorkspaces = selectedWorkspaceIds.map(id => workspacesMap[id]).filter(i => i);
		setWorkspaceProps({
			workspacesIds,
			workspaces,
			workspacesMap,
			loading,
			selectedWorkspaceIds,
			selectedWorkspaces,
			activeWorkspaceIds: selectedWorkspaceIds?.length ? selectedWorkspaceIds : workspacesIds,
			activeWorkspaces: selectedWorkspaces?.length ? selectedWorkspaces : workspaces,
			selectedSingleWSId,
			setSelectedWorkspaces: _.noop,
		})
	}, [workspacesItems, loading]);
	
	// Provide the context value to children
	const contextValue: WorkspacesContextProps = {...workspacesProps, setSelectedWorkspaces};

	return <WorkspacesContext.Provider value={contextValue}>{children}</WorkspacesContext.Provider>;
};

// Custom hook to consume the context
export const useWorkspaces = () => {
	const context = useContext(WorkspacesContext);

	if (!context) {
		throw new Error('useApi must be used within an ApiProvider');
	}

	return context;
};
