import _ from 'lodash';
import { useEffect, useState } from "react";
import {Box, Tab} from '@mui/material';
import {
	LegendToggle as LegendToggleIcon,
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from "@mui/lab";

// components
import PageTitle from "../../components/PageTitle";
import { toUTC } from "utils/transformUtils";

import { useQueryableFilter } from 'utils/hooks';
import {eachDayOfInterval, subDays} from 'date-fns'; 
import { PlaygroundPage } from './Playground/PlaygroundPage';
import { MonthlyTab } from './Monthly/MonthlyTab';

const now = new Date();
const defaultFilter = {tab: 'playground', dateRange: [subDays(now, 7).toISOString().slice(0, 10), subDays(now, 2).toISOString().slice(0, 10)]};
const tabDefaultFilter = {
}
export function DailyPerformancePage() {
	const [datesInterval, setDatesInterval] = useState<string[]>([]);
	const queryableFilter = useQueryableFilter({defaultFilter});
	const {filter, resetQuery, setFilter, sortModel} = queryableFilter;
    

	useEffect(() => {
		if (!filter?.dateRange?.[0] || !filter?.dateRange?.[1]) {return;}
		const days = eachDayOfInterval({
			start: new Date(filter.dateRange[0]),
			end: new Date(filter.dateRange[1]),
		})
		setDatesInterval(days.map(d => toUTC(d).slice(0, 10)).reverse())
	}, [filter.dateRange])

	return (
		<>
			<PageTitle title="Performance" icon={LegendToggleIcon} />
			<TabContext value={filter.tab}>
				<TabList variant="scrollable" onChange={(e, val) => resetQuery({filter: {...(tabDefaultFilter[val] || defaultFilter), tab: val }, sortModel})}>
					<Tab label="Playground" value="playground" />
					<Tab label="Monthly" value="monthly" />
				</TabList>
				<Box sx={{mt: 2}}>
					<TabPanel value="playground" sx={{p: 0}}>
						<PlaygroundPage
						/>
					</TabPanel>
					<TabPanel value="monthly" sx={{p: 0}}>
						<MonthlyTab
						/>
					</TabPanel>
				</Box>
			</TabContext>
		</>
	)
}
