import _ from 'lodash';
import {MonthlyWorkspaceDetail} from './MonthlyWorkspaceDetail';
import { useWorkspaces } from 'context/WorkspaceContext';
import { Flex } from 'components/Flex/Flex';
export function MonthlyTab() {
	const {activeWorkspaces} = useWorkspaces();

	return (
		<Flex direction='column' gap={2} align='stretch'>
			{activeWorkspaces.map(w => <MonthlyWorkspaceDetail key={w.id} workspace={w} />)}
		</Flex>
	)
}