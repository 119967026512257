import axios from 'services/axios';
import _ from 'lodash';
import { toUTC } from 'utils/transformUtils';
import queryString from 'query-string';
import { FetchWorkspaceDailyPerformancePayload } from './api.interface';

export async function fetchWorkspaceDailyPerformance({pagination, filter, sortModel, columns}: FetchWorkspaceDailyPerformancePayload) {
	const pageSize = pagination?.pageSize ?? -1;
	const page = pagination?.page ?? 0;
	const offset = pageSize * page;
	const payload = {
		columns,
		sort: sortModel,
		query: {workspaceDailyPerformance: {...filter.workspaceDailyPerformance}},
	};

	['workspaceId', 'trafficSourceId', 'campaignId', 'offerId', 'siteId', 'networkId'].forEach(key => {
		if (!_.isEmpty(filter[key])) {
			payload.query.workspaceDailyPerformance[key] = {op: 'in', value: filter[key]};
		}
	});

	const query = {
		fromDate: `${toUTC(filter.dateRange[0]).slice(0, 10)}T00:00:00Z`,
		toDate: `${toUTC(filter.dateRange[1]).slice(0, 10)}T23:59:59.999Z`,
		limit: pageSize,
		offset,
	}
	const queryStr = queryString.stringify(query);
	const url = `/workspaceDailyPerformance/query?${queryStr}`;
	const tdpResp = await axios.post(url, payload);

	return tdpResp.data;
}
