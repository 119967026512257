import axios from 'services/axios';
import _ from 'lodash';
import { toUTC } from 'utils/transformUtils';
import queryString from 'query-string';
import { FetchNetworkCommissionsPayload } from './api.interface';

export async function fetchNetworkCommissions({filter, sortModel, columns}: FetchNetworkCommissionsPayload) {
	const ncPayload = {
		columns,
		sort: sortModel,
		query: {networkCommission: {...filter.networkCommission}},
	};
	const hourlyPayload = {
		columns: ['workspaceId', 'month', 'networkId', 'cost', 'defaultRevenue'],
		query: {trafficHourlyPerformance: {}},
	};

	['workspaceId'].forEach(key => {
		if (!_.isEmpty(filter[key])) {
			ncPayload.query.networkCommission[key] = {op: 'in', value: filter[key]};
			hourlyPayload.query.trafficHourlyPerformance[key] = {op: 'in', value: filter[key]};
		}
	});

	const query = {
		fromDate: `${toUTC(filter.dateRange[0]).slice(0, 10)}T00:00:00Z`,
		toDate: `${toUTC(filter.dateRange[1]).slice(0, 10)}T23:59:59.999Z`,
		limit: -1,
		offset: 0,
	}
	const queryStr = queryString.stringify(query);
	const [ncResp, hourlyResp] = await Promise.all([
		axios.post(`/networkCommissions/query?${queryStr}`, ncPayload),
		axios.post(`/trafficHourlyPerformance/query?${queryStr}`, hourlyPayload),
	]);
	
	const hourlyMap = _.keyBy(hourlyResp.data.items, i => `${i.month}-${i.networkId}`);
	const mappedItems = ncResp.data.items.map(i => {
		const hourly = hourlyMap[`${i.month}-${i.network.id}`];
		return {
			cost: hourly?.cost,
			defaultRevenue: hourly?.defaultRevenue,

			...i,
		}
	})
	return mappedItems;
}
